/** @format */

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-privacy-policy-page',
    templateUrl: './privacy-policy-page.component.html',
    styleUrls: ['./privacy-policy-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPolicyPageComponent { }
