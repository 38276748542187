<!-- @format -->

<header class="public-header">
    <app-hailer-logo></app-hailer-logo>
    <div class="public-header-buttons-wrapper">
        <div *ngIf="showLanguageSelector && miscTranslationsLoaded" class="language-change-container">
            <button
                data-cy="language-change-button"
                class="no-button for-text-and-icon"
                [ngClass]="languageMenuOpen ? 'menu-open' : 'menu-closed'"
                (menuOpened)="languageMenuOpen = true"
                (menuClosed)="languageMenuOpen = false"
                [matMenuTriggerFor]="menu">
                <span class="language-button-text">{{ selectedLanguage }}</span>
                <mat-icon svgIcon="hailer-chevron"></mat-icon>
            </button>
            <mat-menu #menu="matMenu" aria-selected="selectedLanguage">
                <button mat-menu-item (click)="changeLanguage('en')">English</button>
                <button mat-menu-item (click)="changeLanguage('fi')">Suomi</button>
                <button mat-menu-item (click)="changeLanguage('sv')">Svenska</button>
            </mat-menu>
        </div>
        <button
            *ngIf="showLogin"
            routerLink="/login"
            mat-flat-button
            [ngClass]="loginAsPrimaryBtn ? 'primary-public-pages' : 'tertiary-highlight-public-pages'">
            {{ 'public.header.log-in' | transloco }}
        </button>
        <button
            *ngIf="showSignUp"
            routerLink="/register"
            mat-flat-button
            [ngClass]="signUpAsPrimaryBtn ? 'primary-public-pages' : 'tertiary-highlight-public-pages'">
            {{ 'public.header.sign-up' | transloco }}
        </button>
    </div>
</header>