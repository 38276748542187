<!-- @format -->

<form [formGroup]="loginForm" (ngSubmit)="sendUserLogin()">
    <div class="flex-column--centered">
        <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field public-forms">
            <mat-label attr.aria-label="{{ 'public.login-form.email_label' | transloco }}">{{
                'public.login-form.email_label' | transloco
            }}</mat-label>
                 <!-- placeholder="{{ 'public.register-form.email_placeholder' | transloco }}" -->
            <input
                data-cy="login-email-input"
                id="email"
                autocomplete="email"
                matInput
                [appAutofocus]="true"
                formControlName="username"
                type="email"
                [ngClass]="{ 'is-invalid': (saving | async) && loginForm.controls.username?.errors }"
                class="form-control" />
            <mat-error *ngIf="emailFieldInvalid()">{{ 'public.register-form.email_invalid_hint' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field public-forms">
            <mat-label attr.aria-label="{{ 'public.login-form.password_label' | transloco }}">{{
                'public.login-form.password_label' | transloco
            }}</mat-label>
              <!-- placeholder="{{ 'public.login-form.enter_password' | transloco }}" -->
            <input
                data-cy="login-password-input"
                id="password"
                autocomplete="current-password"
                matInput
                formControlName="password"
              
                class="form-control"
                [type]="passwordVisible ? 'text' : 'password'" />
            <mat-icon
                class="password-visibility-button public-forms"
                matSuffix
                [svgIcon]="passwordVisible ? 'hailer-show' : 'hailer-hide'"
                (click)="passwordVisible = !passwordVisible"></mat-icon>
            <!--<mat-hint *ngIf="loginForm.controls.password.errors && loginForm.controls.password.errors.required">Password is required</mat-hint>-->
            <mat-error
                data-cy="invalid-credentials-hint"
                *ngIf="loginForm.controls.password?.errors && loginForm.controls.password?.errors?.inValid"
                >{{ 'public.login-form.wrong_hint' | transloco }}</mat-error
            >
            <mat-error
                data-cy="blocked-credentials-hint"
                *ngIf="loginForm.controls.password?.errors && loginForm.controls.password?.errors?.loginBlocked"
                >{{ 'public.login-form.login_blocked' | transloco }}</mat-error
            >
        </mat-form-field>
    </div>
    <div *ngIf="hasForgotPasswordLink && !loginForm.controls.password?.errors?.loginBlocked" class="forgot-password-wrapper">
        <a data-cy="forgot-password-link" class="login-link" [routerLink]="['/forgotpassword']">{{
            'public.login-form.forgot_password_link' | transloco
        }}</a>
    </div>
    <div class="login__button-container--multiple-buttons">
        <button data-cy="login-button" mat-flat-button class="single-row-button primary-public-pages" [disabled]="logInDisabled()">
            {{ (saving | async) ? ('public.login-form.button_login_1' | transloco) : ('public.login-form.button_login_2' | transloco) }}
        </button>
    </div>

    <div class="flex-column--centered">
        <div class="or-separator">
            <span class="or-separator-text">{{ 'public.login-form.or' | transloco }}</span>
        </div>
        <ng-container *ngIf="googleLoginError">
            <span class="login-error">{{ googleLoginError }}</span>
        </ng-container>
        <ng-container *ngIf="!isMobile">
            <div id="googleSignInBtn" class="login-button-container-google-button"></div>
        </ng-container>
        <ng-container *ngIf="isMobile">
            <div class="login-button-container-google-button">
                <!-- Android Google sign in button based on https://developers.google.com/identity/branding-guidelines -->
                <button
                class="single-row-button gsi-material-button"
                style="width: 330px"
                (click)="signInGoogleCapacitor()">
                    <div class="gsi-material-button-state"></div>
                    <div class="gsi-material-button-content-wrapper">
                        <div class="gsi-material-button-icon">
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                style="display: block">
                                <path
                                    fill="#EA4335"
                                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                <path
                                    fill="#4285F4"
                                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                <path
                                    fill="#FBBC05"
                                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                <path
                                    fill="#34A853"
                                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                            </svg>
                        </div>
                        <span class="gsi-material-button-contents">{{
                            'public.login-form.sign_in_google' | transloco
                        }}</span>
                        <span style="display: none;">Sign in with Google</span>
                    </div>
                </button>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="registeringError">
        <span class="login-error">{{ 'public.login-form.register_error' | transloco }}</span>
    </ng-container>
    <ng-container *ngIf="loggingInError">
        <span class="login-error">{{ 'public.login-form.login_error' | transloco }}</span>
    </ng-container>
</form>
