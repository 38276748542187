/** @format */

import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { CoreService } from 'app/_services/core.service';
import { RegistrationData } from '../../../_models/registrationData.model';
import { PasswordStrengthMeterComponent } from '@app/shared/password-strength-meter/password-strength-meter.component';
import { passwordComplexityValidator, passwordConfig } from 'app/_helpers/password-helper';

@Component({
    selector: 'app-register-form',
    templateUrl: './register-form.component.html',
    styleUrls: ['./register-form.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class RegisterFormComponent implements OnInit, OnDestroy {
    @ViewChild('passwordStrengthMeter', {}) passwordStrengthMeter: PasswordStrengthMeterComponent;
    @Input() hasBackButton = false;
    @Input() cid: string;
    @Input() presetEmail: string;
    @Input() errorCode?: number;
    @Output() submitClick: EventEmitter<RegistrationData> = new EventEmitter<RegistrationData>();

    saving: boolean;
    registerForm: UntypedFormGroup;
    queryParams = {};
    errorMsgRegister: string;
    emailTaken = false;
    emailFocused: boolean;
    passwordInputFocused = false;
    passwordConfirmDirty = false;
    visiblePasswords = {
        new: false,
        newConfirm: false,
    };
    passwordScore = new BehaviorSubject(0);

    private registerUser: RegistrationData;
    private email: UntypedFormControl;
    private firstName: UntypedFormControl;
    private lastName: UntypedFormControl;
    private password: UntypedFormControl;
    private passwordConfirm: UntypedFormControl;
    private onDestroy = new Subject<void>();

    constructor(
        private coreService: CoreService,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnInit() {
        this.registerUser = new RegistrationData();
        this.saving = false;
        this.createFormControls();
        this.createForm();

        if (this.cid) {
            this.queryParams = { referer: 'register', cid: this.cid };
        }

        this.email.statusChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.emailTaken = this.email.errors?.emailTaken;
                this.cdr.detectChanges();
            },
        });

        this.registerForm.statusChanges.pipe(takeUntil(this.onDestroy), debounceTime(100)).subscribe({
            next: () => this.cdr.detectChanges(),
        });

        // Changes in password field also check if passwordConfirm matches
        this.password.statusChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                if (!this.passwordConfirmDirty) {
                    return;
                }
                if (this.password.value !== this.passwordConfirm.value) {
                    this.passwordConfirm.setErrors({ passwordsDoNotMatch: true });
                } else {
                    this.passwordConfirm.setErrors(null);
                }
            },
        });
    }

    ngOnDestroy() {
        this.registerUser = new RegistrationData().deserialize({});
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    checkEmailNotExisting(control: AbstractControl): Promise<any> | undefined {
        if (!control.value) {
            return;
        }
        return this.coreService.checkExistingEmail(control.value);
    }

    sendRegisterInfo(event) {
        this.saving = true;
        this.getUserDataFromForm();
        this.submitClick.emit(this.registerUser);
    }

    setEmail(newEmail: string) {
        this.email.setValue(newEmail);
    }

    getEmail(): string {
        return this.email.value;
    }

    setSavingState(newState: boolean) {
        this.saving = newState;
    }

    getSavingState(): boolean {
        return this.saving;
    }

    setPasswordInputFocus(state: boolean, event?: any) {
        // Don't change the focus if password visibility button is clicked
        if (!(!state && event?.relatedTarget?.id === 'newPasswordInputVisibilityIcon')) {
            this.passwordInputFocused = state;
        }
    }

    passwordFieldInvalid() {
        return (
            this.registerForm.controls.password?.invalid &&
            !this.registerForm.controls.password.pristine &&
            !this.registerForm.controls.password.touched
        );
    }

    passwordConfirmFieldInvalid() {
        return (
            this.registerForm.controls.passwordConfirm?.invalid &&
            !this.registerForm.controls.passwordConfirm?.pristine &&
            !this.registerForm.controls.passwordConfirm?.touched
        );
    }

    // "Already have an account? Sign in here" link showing on register page only, not on invitation page:
    get showLogInHereLink(): boolean {
        return this.router.url.includes('register');
    }

    // Email field is autofocused. We don't want to show red outline if user clicks outside of form without interacting with the field first.
    onEmailFocus(): void {
        this.emailFocused = true;
    }

    onEmailBlur(): void {
        this.emailFocused = false;
    }

    get hideEmailRedOutline(): boolean {
        return !this.email.dirty && !this.emailFocused;
    }

    private createFormControls() {
        this.email = new FormControl(
            { value: this.presetEmail ? this.presetEmail : '', disabled: !!this.presetEmail },
            {
                validators: Validators.required,
                asyncValidators: [this.checkEmailNotExisting.bind(this)],
                updateOn: 'blur',
            }
        );
        this.firstName = new UntypedFormControl('', { validators: Validators.required });
        this.lastName = new UntypedFormControl('', { validators: Validators.required });
        this.password = new UntypedFormControl('', {
            validators: [
                Validators.required,
                Validators.minLength(passwordConfig.minLength),
                passwordComplexityValidator(this.passwordScore),
            ],
        });
        this.passwordConfirm = new UntypedFormControl('', {
            validators: [Validators.required, this.checkPasswordMatch.bind(this)],
        });

        this.activatedRoute.queryParams.pipe(take(1), takeUntil(this.onDestroy)).subscribe({
            next: (params: { email?: string; firstname?: string; lastname?: string }) => {
                if (params) {
                    if (params.email) {
                        this.email.setValue(params.email);
                    }

                    if (params.firstname) {
                        this.firstName.setValue(params.firstname);
                    }

                    if (params.lastname) {
                        this.lastName.setValue(params.lastname);
                    }
                }
            },
        });
    }

    private createForm() {
        this.registerForm = new UntypedFormGroup({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            passwordConfirm: this.passwordConfirm,
        });
    }

    private checkPasswordMatch(control: AbstractControl): { [key: string]: any } | null {
        if (control.value === this.password.value) {
            return null;
        }
        return { passwordsDoNotMatch: true };
    }

    private getUserDataFromForm() {
        this.registerUser.firstname = this.firstName.value;
        this.registerUser.lastname = this.lastName.value;
        this.registerUser.email = this.email.value.toLowerCase();
        this.registerUser.password = this.password.value;
    }
}
