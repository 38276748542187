<!-- @format -->

<div class="login-background">
    <app-public-header [showLanguageSelector]="false" [signUpAsPrimaryBtn]="true"></app-public-header>
    <div class="public-sub-pages p-styles">
        <h1>Hailer Terms of Use</h1>

        <p class="public-intro-text">
            These Terms of Use (“Terms”) govern your use of the Hailer application (“Hailer” or “Application”). By accessing or using
            Hailer, you agree to be bound by these Terms. If you disagree with any part of these Terms, then you should discontinue using
            the Application.
        </p>

        <h3>Acceptance of Terms</h3>

        <p>
            By downloading, installing, or otherwise accessing or using Hailer, you confirm that you have read, understood, and agreed to be
            bound by these Terms.
        </p>

        <h3>Changes to the Terms</h3>

        <p>
            We reserve the right to modify these Terms at any time. We will strive to provide at least 30 days notice of any changes. Your
            continued use of Hailer following any changes signifies your acceptance of our amended Terms.
        </p>

        <h3>Registration & Account</h3>

        <p>
            You may be required to register to use some of the features of Hailer. You agree to provide accurate information and to keep it
            updated. You are responsible for maintaining the confidentiality of your account and password.
        </p>

        <h3>Prohibited Activities</h3>

        <p>You may not:</p>

        <ul>
            <li>Use the Application for any illegal purpose.</li>
            <li>Attempt to gain unauthorized access to the Application.</li>
            <li>Engage in any activity that interferes with the performance of the Application.</li>
        </ul>

        <h3>Content</h3>

        <p>
            Hailer allows users to post content. You are responsible for the content you post, and you must ensure it does not violate any
            laws or these Terms.
        </p>

        <h3>Privacy</h3>

        <p>
            Please refer to our <a [routerLink]="'/privacy-policy'" class="login-link">Privacy Policy</a>, which explains how we collect,
            use, and disclose your personal information.
        </p>

        <h3>Intellectual Property</h3>

        <p>
            Hailer and its content, features, and functionality are and will remain the exclusive property of the Application's creators.
            Unauthorized use may result in legal actions.
        </p>

        <h3>Termination</h3>

        <p>
            We may suspend or terminate your access to the Application without prior notice for any reason, including if you violate any of
            these Terms.
        </p>

        <h3>Disclaimers</h3>

        <p>Hailer is provided "as is", without any warranties of any kind, either express or implied.</p>

        <h3>Limitation of Liability</h3>

        <p>
            To the fullest extent permitted by law, neither Hailer nor any of its affiliates, directors, employees, or agents will be liable
            for any damages of any kind.
        </p>

        <h3>Governing Law & Jurisdiction</h3>

        <p>
            These Terms are governed by and construed in accordance with the laws of the specified jurisdiction. Disputes arising from these
            Terms will be resolved in the competent courts of that jurisdiction.
        </p>

        <h3>Contact Information</h3>

        <p>
            If you have any questions about these Terms, please contact us at
            <a href="mailto:support@hailer.com" target="_blank" class="login-link">support&#64;hailer.com</a>
        </p>

        <h3>Entire Agreement</h3>

        <p>
            These Terms constitute the entire agreement between you and Hailer concerning the Application, superseding any prior agreements
            or understandings.
        </p>
    </div>
    <app-public-footer></app-public-footer>
</div>
