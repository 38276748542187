<!-- @format -->
<div
    class="login-background"
    *ngIf="(state | async) !== 'authenticated'"
    [ngStyle]="{ 'visibility': (loadingProgress.loaded | async) ? 'visible' : 'hidden' }">
    <app-public-header [showLogin]="false"></app-public-header>
    <div class="public-page-scroll-wrapper">
        <div class="content-container">
            <mat-card *ngIf="getState() === 'login'" class="login-card">
                <mat-card-content>
                    <div>
                        <div class="flex-column--centered">
                            <h1 class="login-header">{{ 'public.login-page.log-in' | transloco }}</h1>
                        </div>
                        <div *ngIf="getState() === 'login'" class="flex-column--centered">
                            <p class="login-text">
                                {{ 'public.login-page.no_account' | transloco }}
                                <a data-cy="register-account-link" class="login-link" [routerLink]="['/register']">
                                    {{ 'public.login-page.register_acc' | transloco }}
                                </a>
                            </p>
                        </div>
                        <app-login-form
                            [hasForgotPasswordLink]="true"
                            [hasRememberMeButton]="true"
                            [presetUserName]="presetUserName"
                            (submitClick)="login($event)"></app-login-form>
                    </div>

                    <div *ngIf="getState() === 'login'" class="flex-column--centered devtools-button">
                        <button *ngIf="showDevTools" mat-flat-button [routerLink]="['/devtools']" color="basic">
                            {{ 'public.login-page.dev_tools' | transloco }}
                            <mat-icon svgIcon="hailer-dev"></mat-icon>
                            <mat-icon svgIcon="hailer-settings"></mat-icon>
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <app-public-footer></app-public-footer>
    </div>
</div>
