/** @format */

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-terms-of-use-page',
    templateUrl: './terms-of-use-page.component.html',
    styleUrls: ['./terms-of-use-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfUsePageComponent { }
