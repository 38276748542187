<!-- @format -->

<footer class="public-footer">
    <div class="company-info">
        <app-hailer-logo></app-hailer-logo>
        <p class="footer-copyright">&copy; 2025 Hailer Oy</p>

        <a
            href="https://www.youtube.com/channel/UCtud_4Y8c6UB9Qlxz6x2how/videos?view=0&sort=da"
            target="_blank"
            aria-label="Hailer Youtube">
            <mat-icon svgIcon="hailer-youtube" class="footer-youtube-link"></mat-icon>
        </a>
    </div>

    <nav class="footer-links-alignment">
        <ul class="footer-links">
            <li><a [routerLink]="'/contact'">Contact</a></li>
            <li><a [routerLink]="'/terms-of-use'">Terms of Use</a></li>
            <li><a [routerLink]="'/pricing'">Pricing</a></li>
            <li><a [routerLink]="'/privacy-policy'">Privacy Policy</a></li>
        </ul>
    </nav>

    <div class="download-links">
        <a href="https://apps.apple.com/fi/app/hailer/id1239211971" target="_blank" class="app-download-link">
            <img src="../../../../assets/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Apple iStore download button" />
        </a>

        <a href="https://play.google.com/store/apps/details?id=hailer.com.hailer&hl=en&gl=US" target="_blank" class="app-download-link">
            <img src="../../../../assets/img/GetItOnGooglePlay_Badge_Web_color_English.png" alt="Google Play download button" />
        </a>
    </div>
</footer>
