<!-- @format -->

<div class="login-background" *ngIf="(state | async) !== 'connecting'">
    <app-public-header [showLogin]="false" [showSignUp]="false"></app-public-header>
    <div *ngIf="invitation && invitation.user && (state | async) === 'login'" class="center-content">
        <div class="review-container">
            <h1 data-cy="invitation-header" class="invitation-header">{{ 'core.invite-review-page.header_invite_to_join' | transloco }}</h1>
            <div data-cy="invitation-text" class="invitation-text">
                <p class="network-detail-name">{{ invitation.network.name }}</p>
                <p class="network-detail-detail">{{ invitation.email }}</p>
            </div>
            <div class="login-button-container-two-buttons-column">
                <button
                    data-cy="accept-invite-button"
                    [disabled]="isManagedUser"
                    mat-flat-button
                    class="primary-public-pages"
                    (click)="acceptInvitation(invitation.invite_key)">
                    {{ 'core.invite-review-page.button_accept' | transloco }}
                </button>
                <button
                    data-cy="decline-invite-button"
                    mat-flat-button
                    class="tertiary-highlight-public-pages warn-text"
                    (click)="declineInvitation(invitation.invite_key)">
                    {{ 'core.invite-review-page.button_decline' | transloco }}
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="invitation && !invitation.user && !invitationCodeError && (state | async) === 'login'" class="center-content">
        <div class="register-form">
            <h1 class="invitation-header">{{ 'core.invite-review-page.header_invite_to_join' | transloco }}</h1>
            <h2 class="login-header">{{ invitation.network.name }}</h2>
            <app-register-form
                [presetEmail]="invitation.email"
                [hasBackButton]="true"
                (submitClick)="registerAndAcceptInvitation($event, invitation.invite_key)"></app-register-form>
        </div>
    </div>

    <div class="invitation-error-container" *ngIf="invitationCodeError">
        <h3>{{ 'core.invite-review-page.header_error' | transloco }}</h3>
        <p *ngIf="rerouteTimer">
            {{ 'core.invite-review-page.error_text_redirect_1' | transloco }}{{ rerouteTimer }}
            {{ 'core.invite-review-page.error_text_redirect-2' | transloco }}
        </p>
    </div>
</div>
