/** @format */

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPageComponent implements OnInit {

    ngOnInit(): void {
        
    }

    getLanguage(): string {
        return localStorage.getItem('selectedLanguage') || 'en';
    }
 }
