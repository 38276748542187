<!-- @format -->

<div class="login-background">
    <!-- We don't want to show the header component on copying workspace screen while user is logged in, therefore showing it only when registering -->
    <app-public-header *ngIf="showRegistration" [showSignUp]="false"></app-public-header>
    <div class="public-page-scroll-wrapper">
        <div class="content-wrapper">
            <div *ngIf="showRegistration" class="register-header">
                <p class="first">goodbye manmade chaos</p>
                <p class="second"><span class="hello">hello</span>&nbsp;natural cooperation</p>
            </div>
            <mat-card class="login-card">
                <mat-card-content>
                    <!-- Register form  -->
                    <div *ngIf="page === 'registerform'">
                        <div class="flex-column--centered">
                            <h2 *ngIf="showRegistration" class="login-header register">{{ 'public.register-page.create_account' | transloco }}</h2>
                        </div>
                        <app-register-form
                            *ngIf="showRegistration"
                            [errorCode]="errorCode"
                            [hasBackButton]="true"
                            [cid]="copyNetworkId"
                            (submitClick)="register($event)"></app-register-form>

                        <div class="flex-column--centered">
                            <ng-container *ngIf="errorMsgRegister">
                                <div class="login-error">
                                    <label>{{ errorMsgRegister }}</label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="googleLoginError">
                                <div class="login-error">
                                    <label>{{ googleLoginError }}</label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="showRegistration">
                                <div class="or-separator">
                                    <span class="or-separator-text">{{ 'public.register-page.or' | transloco }}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!isMobile && showRegistration">
                                <div id="googleSignUpBtn" class="login-button-container-google-button"></div>
                            </ng-container>
                            <ng-container *ngIf="isMobile && showRegistration">
                                <div class="login-button-container-google-button">
                                    <!-- Android Google sign in button based on https://developers.google.com/identity/branding-guidelines -->
                                    <button
                                        class="single-row-button gsi-material-button"
                                        style="width: 330px"
                                        (click)="signInGoogleCapacitor()">
                                        <div class="gsi-material-button-state"></div>
                                        <div class="gsi-material-button-content-wrapper">
                                            <div class="gsi-material-button-icon">
                                                <svg
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 48 48"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    style="display: block">
                                                    <path
                                                        fill="#EA4335"
                                                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                                    <path
                                                        fill="#4285F4"
                                                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                                    <path
                                                        fill="#FBBC05"
                                                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                                    <path
                                                        fill="#34A853"
                                                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                                </svg>
                                            </div>
                                            <span class="gsi-material-button-contents">{{
                                                'public.login-form.sign_in_google' | transloco
                                            }}</span>
                                            <span style="display: none;">Sign in with Google</span>
                                        </div>
                                    </button>
                                </div>
                            </ng-container>
                        </div>

                        <div *ngIf="!showRegistration" class="flex-column--centered">
                            <h2 data-cy="create-workspace-text" class="login-sub-header">
                                {{ 'public.register-page.create_workspace' | transloco }}
                            </h2>
                            <div class="login-button-container-two-buttons-column">
                                <button
                                    data-cy="create-workspace-copy-button"
                                    *ngIf="errorCode !== 501"
                                    mat-flat-button
                                    class="primary-public-pages"
                                    (click)="createWorkspaceCopy()"
                                    [disabled]="saving">
                                    <span>{{ 'public.register-page.button_create_workspace' | transloco }}</span>
                                </button>
                                <button
                                    data-cy="back-to-hailer-button"
                                    mat-flat-button
                                    class="tertiary-highlight-public-pages"
                                    (click)="routeToDefaultView()"
                                    [disabled]="saving">
                                    <span>{{ 'public.register-page.button_back' | transloco }}</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Validation email sent -->
                    <div class="flex-column--centered" *ngIf="page === 'emailsent'">
                        <h2 class="login-sub-header">
                            {{ 'public.register-page.validation_sent' | transloco }}
                        </h2>
                        <p class="login-text">
                            {{ 'public.register-page.validation_sent_to' | transloco }}
                            <strong>{{ emailSentToAddress }}</strong>
                        </p>

                        <div class="flex-column--centered">
                            <div *ngIf="errorMsgRegister" class="login-error">
                                <label>{{ errorMsgRegister }}</label>
                            </div>
                            <div *ngIf="googleLoginError" class="login-error">
                                <label>{{ googleLoginError }}</label>
                            </div>
                            <div [hidden]="!showRegistration" id="googleSignUpBtn" class="login-button-container-google-button"></div>
                            <div *ngIf="isMobile && showRegistration" class="login-button-container-google-button">
                                <!-- Android Google sign in button based on https://developers.google.com/identity/branding-guidelines -->
                                <button
                                    class="single-row-button gsi-material-button"
                                    style="width: 330px"
                                    (click)="signInGoogleCapacitor()">
                                    <div class="gsi-material-button-state"></div>
                                    <div class="gsi-material-button-content-wrapper">
                                        <div class="gsi-material-button-icon">
                                            <svg
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 48 48"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                style="display: block">
                                                <path
                                                    fill="#EA4335"
                                                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                                <path
                                                    fill="#4285F4"
                                                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                                <path
                                                    fill="#FBBC05"
                                                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                                <path
                                                    fill="#34A853"
                                                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                                <path fill="none" d="M0 0h48v48H0z"></path>
                                            </svg>
                                        </div>
                                        <span class="gsi-material-button-contents">{{
                                            'public.login-form.sign_in_google' | transloco
                                        }}</span>
                                        <span style="display: none">Sign in with Google</span>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div *ngIf="!showRegistration" class="flex-column--centered">
                            <h2 class="login-sub-header">
                                {{ 'public.register-page.create_workspace' | transloco }}
                            </h2>
                            <div class="login-button-container-two-buttons-column">
                                <button
                                    *ngIf="errorCode !== 501"
                                    mat-flat-button
                                    class="primary-public-pages"
                                    (click)="createWorkspaceCopy()"
                                    [disabled]="saving">
                                    <span>{{ 'public.register-page.button_create_workspace' | transloco }}</span>
                                </button>
                                <button mat-flat-button class="tertiary-highlight-public-pages" (click)="routeToDefaultView()" [disabled]="saving">
                                    <span>{{ 'public.register-page.button_back' | transloco }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <div *ngIf="showRegistration" class="story-time">
                <p>
                    Imagine walking in a quiet forest. There are no managers, no corrupt hierarchies and no manuals yet everything just
                    works - <span class="story-highlight">Nature’s way of getting stuff done.</span>
                </p>
                <p>Now imagine an office. Packed with rules, managers, elbowing and noise - Instead of flowing, it usually sucks.</p>
                <p>
                    <span class="story-highlight">Cooperation</span> works best when people share a common goal, participate voluntarily,
                    benefit from working together and use a system that naturally supports these values, while ensuring the right
                    information reaches the right people at the right time.
                </p>
                <p><span class="story-highlight">Hailer is that system.</span></p>
            </div>
        </div>
        <app-public-footer></app-public-footer>
    </div>
</div>
