/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';

import { CoreService } from 'app/_services/core.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-login-token',
    templateUrl: './login-token.component.html',
    styleUrls: ['./login-token.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
            },
        },
    ],
})
export class LoginTokenComponent implements OnInit {
    loginToken: string | null;

    private onDestroy = new Subject<void>();

    constructor(private route: ActivatedRoute, private core: CoreService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.route.queryParams.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            this.loginToken = params.token;
            this.verifyToken();
        });
    }

    verifyToken(): void {
        if (!this.loginToken) {
            return;
        }
        this.core
            .loginToken(this.loginToken)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                error: error => {
                    console.error('error logging in', error);
                    this.core.setState('login');
                    this.loginToken = null;
                    this.cdr.detectChanges();
                },
            });
    }
}
