/** @format */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { UserLogin } from '../../../_models';
import { Company } from '../../../_models/company.model';
import { LoginFormComponent } from '../../components/login-form/login-form.component';
import { CoreService, SessionState } from 'app/_services/core.service';
import { RoutingService } from 'app/_services/routing.service';
import { UserService } from 'app/_services/user.service';
import { LoadingProgressService } from 'app/_services/loading-progress.service';
import { TranslateService } from 'app/_services/translate.service';

@Component({
    selector: 'app-login',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class LoginPageComponent implements OnInit, OnDestroy {
    @ViewChild(LoginFormComponent, { static: false }) logForm: LoginFormComponent;

    loginForm: UntypedFormGroup;
    returnUrl: string;
    // Username for which user has renewed their password
    presetUserName: string;

    state: Observable<SessionState>;

    // Login form fields
    password: UntypedFormControl;
    network: Company;
    referer: string;
    cid: string;

    debug = false;

    private onDestroy = new Subject<void>();
    private routeAuthentication = true;

    constructor(
        private core: CoreService,
        private route: RoutingService,
        private userService: UserService,
        public loadingProgress: LoadingProgressService,
        private translateService: TranslateService
    ) {}

    async ngOnInit() {
        await this.translateService.translationLoaded('misc');
        this.state = this.core.state;
        this.state.pipe(takeUntil(this.onDestroy)).subscribe({
            next: newState => {
                if (newState === 'authenticated' && this.routeAuthentication && this.core.user.value) {
                    this.routeAuthentication = false;

                    const invitationKey = this.route.snapshot.queryParams.invitationKey;
                    if (!invitationKey) {
                        return void this.routeAfterAuthenticated();
                    }

                    this.userService
                        .acceptInvitation(invitationKey)
                        .pipe(takeUntil(this.onDestroy))
                        .subscribe({
                            next: () => this.routeAfterAuthenticated(),
                            error: error => {
                                console.error('Failed to accept invitation: ', error);
                                this.routeAfterAuthenticated();
                            },
                        });
                    return;
                }
            },
        });

        // Get return url from route parameters or default to '/'
        if (this.route.snapshot && this.route.snapshot.queryParams) {
            this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
            this.presetUserName = this.route.snapshot.queryParams.withUsername || '';

            this.referer = this.route.snapshot.queryParams.referer || '';
            this.cid = this.route.snapshot.queryParams.cid || '';
        }
        this.getState();

        try {
            if (localStorage.getItem('debug')) {
                this.debug = true;
            }
        } catch (error) {
            console.error(error);
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    routeAfterAuthenticated() {
        if (this.referer) {
            this.route.navigate([this.referer], { queryParams: { cid: this.cid } });
        } else {
            this.route.toDefaultView();
        }
    }

    login(credentials: UserLogin) {
        this.core
            .login(credentials.username, credentials.password)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                error: error => {
                    if (error.code === 128) {
                        this.logForm.setLoginBlockedError();
                    } else {
                        this.logForm.setInvalidLoginErrors();
                    }
                    console.error('error logging in', error);
                    this.logForm.setSavingState(false);
                    this.core.setState('login');
                },
            });
    }

    getState() {
        if (this.core.getState() !== 'authenticated') {
            return this.core.getState();
        } else if (this.core.getState() === 'authenticated' && this.routeAuthentication) {
            this.routeAuthentication = false;
            this.route.toDefaultView();
        }
    }

    get showDevTools(): boolean {
        const debugMode = !!localStorage.getItem('debug');
        const locallyHosted = window.location.hostname === 'localhost';

        return debugMode && locallyHosted;
    }
}
