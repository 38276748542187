import { PermitKeys } from './permission-keys';

export type WorkflowPermission = 'admin' | 'any' | 'ownteam' | 'own' | 'none' | 'guest';
export type WorkflowPhasePermission = 'edit' | 'view' | 'none';
export type CalendarPermission = 'admin' | 'user';
export type WorkspaceRoleTypes = 'user' | 'admin' | 'owner' | 'guest' | 'inviter';

export interface PermissionPath {
    type: 'network' | 'user' | 'team' | 'group';
    id: string;
    permission: WorkflowPermission | WorkflowPhasePermission | CalendarPermission;
}

export interface UserWorkspacePermissions {
    [uid: string]: {
        [cid: string]: UserPermissionQueryBlock;
    }
}

export interface UserWorkflowPermissionMap {
    [uid: string]: WorkspaceWorkflowPermissionMap;
}

export interface WorkspaceWorkflowPermissionMap {
    [workspaceId: string]: WorkflowPermissionMap;
}

export interface WorkflowPermissionMap {
    [workflowId: string]: WorkflowPermissions;
}

export interface WorkflowPermissions {
    hasAccess: boolean;
    isAdmin: boolean;
    /** Active permission derived from highest permission in permissions list */
    permission: WorkflowPermission;
    /** All matching permissions */
    permissions: PermissionPath[];
    phases: {
        [phaseId: string]: {
            /** Phase Name only for detailed view */
            name: string;
            hasAccess: boolean;
            /** Active permission for phase derived highest permission in permissions list or being workflow admin */
            permission: WorkflowPhasePermission;
            /** All matching permissions */
            permissions: PermissionPath[];
            /** If a phase has members it is considered restricted */
            restricted: boolean;
        }
    },
    /** List of all teams the user is member of */
    teams: string[]
}

export interface PermissionOptions {
    items?: PermissionSelection
}

export interface PermissionCacheFilter {
    workspaceIds?: string[] | '*',
    workflowIds?: string[] | '*',
    calendarIds?: string[] | '*',
    insightIds?: string[] | '*',
    appIds?: string[] | '*'
}

/** Limit permission query to selected ids only. */
export interface PermissionSelection {
    workflowIds?: string[],
    calendarIds?: string[],
    insightIds?: string[],
    appIds?: string[]
}

export interface UserCalendarPermissionMap {
    [uid: string]: WorkspaceCalendarPermissionMap;
}

export interface WorkspaceCalendarPermissionMap {
    [workspaceId: string]: CalendarPermissionMap;
}

export interface CalendarPermissionMap {
    [calendarId: string]: CalendarPermissions;
}

export interface CalendarPermissions {
    hasAccess: boolean;
    permissions: PermissionPath[];
    permission: 'edit'
}

export interface InsightPermissionMap {
    [calendarId: string]: InsightPermissions;
}

export interface InsightPermissions {
    hasAccess: boolean;
    permissions: PermissionPath[];
    permission: 'edit'
}

export interface Role {
    _id: string;
    cid: string;
    uid: string;
    name: string;
    created: number;
    updated: number;
    description?: string;
    permits?: PermitKeys[];
}

export interface RoleMap {
    [roleId: string]: Role;
}

export interface WorkspaceRoleMap {
    [workspaceId: string]: RoleMap
}

export interface WorkspaceRolesOptions {
    isAdmin: boolean;
    isOwner: boolean;
    isGuest: boolean;
    isInviter: boolean;
    isUser: boolean;
    custom?: Role;
    managedUser?: boolean;
}

export interface UserPermissionQueryBlock {
    cid: string;
    uid: string;
    roles: WorkspaceRolesOptions;
    permissionItems: string[];
    teams: string[];
}

export interface UserPermissionMap {
    [uid: string]: PermissionMap
}

export interface WorkspacePermissions {
    workflow: WorkflowPermissionMap;
    calendar: CalendarPermissionMap;
    workspace: WorkspaceRolesOptions;
    insight: InsightPermissionMap;
    app: AppPermissionMap;
    teams: string[];
    permits: string[];
}

export interface PermissionMap {
    [workspaceId: string]: WorkspacePermissions;
}

export const PermissionErrorCodes = {
    missing: 404,
    customRoleMissing: 420,
};

export interface PermissionError extends Error {
    code?: number;
    workspaceId?: string;
}

export type PermissionCheckReturn = [error?: PermissionError, access?: true];

export interface AppPermissionMap {
    [appId: string]: AppPermissions;
}

export interface AppPermissions {
    hasAccess: boolean;
    permissions: PermissionPath[];
    permission: 'edit'
}

export interface RoleUserIdsToUpdate {
    [userId: string]: boolean | null
}

export interface PermissionCacheWorkspaceDocument {
    workspace: WorkspaceRolesOptions
    teams: string[];
    permits: string[];
}

export type PermissionCacheDocumentTypes = 'workflow' | 'calendar' | 'workspace' | 'insight' | 'app';

export interface PermissionCacheDocument {
    _id: string;
    type: PermissionCacheDocumentTypes;
    created: number;
    updated?: number;
    userId: string;
    workspaceId: string;
    documentId: string;
    document: WorkflowPermissions | CalendarPermissions | PermissionCacheWorkspaceDocument | InsightPermissions | AppPermissions;
}