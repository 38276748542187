<!-- @format -->

<div class="login-background" *ngIf="!loginToken">
    <app-public-header [showLogin]="false" [showSignUp]="false"></app-public-header>
    <div class="login-error-container">
        <h1>404</h1>
        <h2>{{ 'public.login-token.page_gone' | transloco }}</h2>
        <p class="ns-text-regular-2">{{ 'public.login-token.link_expired' | transloco }}</p>
        <div class="login-button-container-one-button">
            <button mat-flat-button class="primary-public-pages" [routerLink]="['/login']" data-cy="back-to-login-button">
                <span>{{ 'public.forgot-password-page.button_back_to_login' | transloco }}</span>
            </button>
        </div>
    </div>
</div>
