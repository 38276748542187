/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordPageComponent } from './public/pages/forgot-password-page/forgot-password-page.component';
import { PageNotFoundComponent } from './public/pages/page-not-found/page-not-found.component';
import { LoginPageComponent } from './public/pages/login-page/login-page.component';
import { RegisterPageComponent } from './public/pages/register-page/register-page.component';
import { ValidateEmailResolver } from './_models/validateEmailResolver.model';
import { DevelopmentPageComponent } from './public/pages/development-page/development-page.component';
import { LoginTokenComponent } from './public/pages/login-token/login-token.component';
import { FrontPageGuard } from './_guards/frontpage.guard';
import { AuthGuard } from './_guards/auth.guard';
import { RedirectComponent } from './public/redirect/redirect.component';
import { EntraLoginPageComponent } from './public/pages/entra-login-page/entra-login-page.component';
import { UpdateGuard } from './_guards/update.guard';
import { DigiOneLoginPageComponent } from './public/pages/digione-login-page/digione-login-page.component';
import { TermsOfUsePageComponent } from './public/pages/terms-of-use-page/terms-of-use-page.component';
import { PrivacyPolicyPageComponent } from './public/pages/privacy-policy-page/privacy-policy-page.component';
import { PricingPageComponent } from './public/pages/pricing-page/pricing-page.component';
import { ContactPageComponent } from './public/pages/contact-page/contact-page.component';

const routes: Routes = [
    {
        path: '',
        component: LoginPageComponent,
        // Can this be removed if DigiOne is not using the location.hash?
        canActivate: [FrontPageGuard],
    },
    {
        path: 'digione-login',
        component: DigiOneLoginPageComponent,
    },
    {
        path: 'code',
        component: EntraLoginPageComponent,
    },
    {
        path: 'entralogin',
        component: EntraLoginPageComponent,
    },
    {
        path: 'entralogin/:clientId/:tenantId',
        component: EntraLoginPageComponent,
    },
    {
        path: 'login',
        component: LoginPageComponent,
    },
    {
        path: 'redirect/:providerId',
        component: RedirectComponent,
    },
    {
        path: 'register',
        component: RegisterPageComponent,
    },
    {
        path: 'validate',
        component: LoginPageComponent,
        resolve: {
            validate: ValidateEmailResolver,
        },
    },
    {
        path: 'unlockaccount',
        component: LoginTokenComponent,
    },
    {
        path: 'forgotpassword',
        component: ForgotPasswordPageComponent,
    },
    {
        path: 'forgotpassword/:ticketId',
        component: ForgotPasswordPageComponent,
    },
    {
        path: 'devtools',
        component: DevelopmentPageComponent,
    },
    {
        path: '404',
        component: PageNotFoundComponent,
    },
    {
        path: 'terms-of-use',
        component: TermsOfUsePageComponent,
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyPageComponent,
    },
    {
        path: 'pricing',
        component: PricingPageComponent,
    },
    {
        path: 'contact',
        component: ContactPageComponent,
    },
    {
        path: 'invite',
        loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
        // CanActivate: [AuthGuard]
    },
    {
        path: 'feed',
        loadChildren: () => import('./feed/feed.module').then(m => m.FeedModule),
        canActivate: [AuthGuard, UpdateGuard],
    },
    {
        path: 'discussions',
        loadChildren: () => import('./discussion/discussion.module').then(m => m.DiscussionModule),
        canActivate: [AuthGuard, UpdateGuard],
    },
    {
        path: 'people',
        loadChildren: () => import('./people/people.module').then(m => m.PeopleModule),
        canActivate: [AuthGuard, UpdateGuard],
    },
    {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
        canActivate: [AuthGuard, UpdateGuard],
    },
    {
        path: 'activities',
        loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule),
        canActivate: [AuthGuard, UpdateGuard],
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard, UpdateGuard],
    },
    {
        path: 'development',
        loadChildren: () => import('./development/development.module').then(m => m.DevelopmentModule),
        canActivate: [AuthGuard, UpdateGuard],
    },
    {
        path: 'apps',
        loadChildren: () => import('./app/app.module').then(m => m.AppModule),
        canActivate: [AuthGuard, UpdateGuard],
    },
    {
        path: '**',
        redirectTo: '#/404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
