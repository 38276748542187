<!-- @format -->

<form id="register-form" [formGroup]="registerForm" (ngSubmit)="sendRegisterInfo($event)">
    <div class="flex-column--centered">
        <div *ngIf="showLogInHereLink" class="signin-text-wrapper">
            <p class="login-text">
                {{ 'public.register-form.have_account' | transloco }}
                <a [routerLink]="['/login']" data-cy="log-in-here-link" [queryParams]="queryParams" class="login-link"
                    >{{ 'public.register-form.login_text' | transloco }}
                </a>
            </p>
        </div>
        <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field public-forms" [class.default-email-field]="hideEmailRedOutline">
            <mat-label attr.aria-label="{{ 'public.register-form.email_label' | transloco }}" data-cy="register-form-email-label">{{
                'public.register-form.email_label' | transloco
            }}</mat-label>
            <!-- placeholder="{{ 'public.register-form.email_placeholder' | transloco }}" -->
            <input
                data-cy="register-form-email-input"
                id="email"
                autocomplete="email"
                matInput
                [appAutofocus]="true"
                formControlName="email"
                type="email"
                name="email"
                class="form-control"
                (blur)="onEmailBlur()"
                (focus)="onEmailFocus()" />
            <mat-error *ngIf="registerForm.controls.email?.value && emailTaken"
                >{{ 'public.register-form.email_used_hint' | transloco }}
            </mat-error>
            <mat-error *ngIf="registerForm.controls.email?.errors?.invalid">{{
                'public.register-form.email_invalid_hint' | transloco
            }}</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field public-forms">
            <mat-label attr.aria-label="{{ 'public.register-form.firstname_label' | transloco }}" data-cy="register-form-firstname-label">{{
                'public.register-form.firstname_label' | transloco
            }}</mat-label>
             <!-- placeholder="{{ 'public.register-form.firstname_placeholder' | transloco }}" -->
            <input
                data-cy="register-form-firstname-input"
                id="firstName"
                autocomplete="given-name"
                matInput
                formControlName="firstName"
                type="text"
                name="firstName"
                class="form-control" />
        </mat-form-field>
        <mat-form-field color="accent" floatLabel="always" appearance="outline" class="form-field public-forms">
            <mat-label attr.aria-label="{{ 'public.register-form.lastname_label' | transloco }}" data-cy="register-form-lastname-label">{{
                'public.register-form.lastname_label' | transloco
            }}</mat-label>
            <!-- placeholder="{{ 'public.register-form.lastname_placeholder' | transloco }}" -->
            <input
                data-cy="register-form-lastname-input"
                id="familyName"
                autocomplete="family-name"
                matInput
                formControlName="lastName"
                type="text"
                name="lastName"
                class="form-control" />
        </mat-form-field>
        <mat-form-field
            color="accent"
            floatLabel="always"
            appearance="outline"
            class="form-field public-forms"
            [ngClass]="{ 'mat-form-field-invalid': passwordFieldInvalid() }">
            <app-password-strength-meter
                #passwordStrengthMeter
                data-cy="register-form-password-strength-meter"
                [passwordScore]="(passwordScore | async) ?? 0"
                [passwordInputFocused]="passwordInputFocused"
                id="password-strength-meter-tooltip"
                aria-hidden="“false”"></app-password-strength-meter>
            <mat-label attr.aria-label="{{ 'public.register-form.password_label' | transloco }}" data-cy="register-form-password-label">{{
                'public.register-form.password_label' | transloco
            }}</mat-label>
             <!-- placeholder="{{ 'public.register-form.create_password_placeholder' | transloco }}" -->
            <input
                data-cy="register-form-password-input"
                id="new-password"
                autocomplete="new-password"
                matInput
                formControlName="password"
                name="password"
                class="form-control"
                (focus)="setPasswordInputFocus(true)"
                (focusout)="setPasswordInputFocus(false, $event)"
                [type]="visiblePasswords.new ? 'text' : 'password'"
                aria-describedby="password-strength-meter-tooltip" />
            <mat-icon
                matSuffix
                id="newPasswordInputVisibilityIcon"
                tabindex="0"
                class="password-visibility-button public-forms"
                [svgIcon]="visiblePasswords.new ? 'hailer-show' : 'hailer-hide'"
                (click)="visiblePasswords.new = !visiblePasswords.new"></mat-icon>
        </mat-form-field>
        <mat-form-field
            color="accent"
            floatLabel="always"
            appearance="outline"
            class="form-field public-forms"
            [ngClass]="{ 'mat-form-field-invalid': passwordConfirmFieldInvalid() }">
            <mat-label
                attr.aria-label="{{ 'public.register-form.repeat_password_label' | transloco }}"
                data-cy="register-form-repeat-password-label"
                >{{ 'public.register-form.repeat_password_label' | transloco }}</mat-label
            >
            <!-- placeholder="{{ 'public.register-form.confirm_password_placeholder' | transloco }}" -->
            <input
                data-cy="register-form-repeat-password-input"
                id="new-password-repeat"
                autocomplete="off"
                matInput
                formControlName="passwordConfirm"
                class="form-control"
                name="passwordConfirm"
                [type]="visiblePasswords.newConfirm ? 'text' : 'password'"
                (focus)="passwordConfirmDirty = true" />
            <mat-icon
                matSuffix
                tabindex="0"
                class="password-visibility-button public-forms"
                [svgIcon]="visiblePasswords.newConfirm ? 'hailer-show' : 'hailer-hide'"
                (click)="visiblePasswords.newConfirm = !visiblePasswords.newConfirm"></mat-icon>
            <mat-hint
                *ngIf="registerForm.controls.passwordConfirm?.errors && registerForm.controls.passwordConfirm?.errors?.passwordsDoNotMatch"
                class="red-hint"
                >{{ 'public.register-form.if_no_match' | transloco }}</mat-hint
            >
        </mat-form-field>
        <div class="policy-checkbox-wrapper">
            <mat-checkbox #policyRef data-cy="register-form-policy-checkbox" class="checkbox-thin">
                <p class="login-text">
                    {{ 'public.register-form.policy_text_1' | transloco }}
                    <a [routerLink]="'/privacy-policy'" target="_blank" class="login-link muted"
                        >{{ 'public.register-form.policy_text_2' | transloco }}
                    </a>
                    {{ 'public.register-form.and' | transloco }}
                    <a [routerLink]="'/terms-of-use'" target="_blank" class="login-link muted"
                        >{{ 'public.register-form.terms-of-use' | transloco }}
                    </a>
                </p>
            </mat-checkbox>
        </div>
    </div>
    <ng-container *ngIf="errorMsgRegister">
        <span class="login-error">{{ errorMsgRegister }}</span>
    </ng-container>
    <div class="login__button-container--multiple-buttons">
        <button class="single-row-button primary-public-pages" mat-flat-button *ngIf="saving" [disabled]="saving">
            {{ 'public.register-form.button_saving' | transloco }}
        </button>
        <button
            class="single-row-button primary-public-pages"
            mat-flat-button
            *ngIf="!saving"
            type="submit"
            [disabled]="!registerForm.valid || !policyRef.checked">
            {{
                errorCode === 501
                    ? ('public.register-form.button_register_1' | transloco)
                    : ('public.register-form.button_register_2' | transloco)
            }}
        </button>
    </div>
</form>
