<!-- @format -->

<div class="login-background">
    <app-public-header [showLanguageSelector]="false" [signUpAsPrimaryBtn]="true"></app-public-header>

    <div class="public-sub-pages p-styles">
        <h1>Contact Sales team</h1>
        <p class="public-intro-text">
            If you have any questions with pricing and finding the right solutions for your team, contact us or book a demo
        </p>

        <div class="contact-buttons-wrapper">
            <a mat-flat-button href="https://form.hailer.com/846f393a84406e85dbc150ffdfff8352"
            target="_blank" class="primary-public-pages  semi-wide">Contact us</a>
            <a mat-flat-button class="tertiary-highlight-public-pages semi-wide" href="https://calendly.com/jaakko-nieminen-hailer/15min" target="_blank">Book a demo</a>
        </div>

        <div class="teamperson-card-wrapper">
            <app-teamperson-card
                name="Nico Larsen"
                imageSrc="/assets/img/team/nico_no_bg.webp"
                jobTitle="CEO"
                description="Business development, sales and consultation"
                phoneNumber="+358405177920"
                email="nico.larsen@hailer.com"
                linkedInUrl="https://fi.linkedin.com/in/nico-larsen-35a36519">
            </app-teamperson-card>
            <app-teamperson-card
                name="Jaakko Nieminen"
                imageSrc="/assets/img/team/jaakko_no_bg.webp"
                jobTitle="Head of Sales"
                description="Customer acquisition, training and consulting"
                phoneNumber="+358403516789"
                email="jaakko.nieminen@hailer.com"
                linkedInUrl="https://www.linkedin.com/in/jaakko-nieminen-a11b7a70/">
            </app-teamperson-card>
            <app-teamperson-card
                name="Olli Houttu"
                imageSrc="/assets/img/team/olli_no_bg.webp"
                jobTitle="Customer Success Manager"
                description="Customer success and projects"
                phoneNumber="+358406877914"
                email="olli.houttu@hailer.com"
                linkedInUrl="https://www.linkedin.com/in/olli-houttu-b2a8aa10">
            </app-teamperson-card>
            <app-teamperson-card
                name="Juhani Yli-Karjanmaa"
                imageSrc="/assets/img/team/juhani_no_bg.webp"
                jobTitle="Customer Support Specialist"
                description="Happy salesman who never runs out of ideas"
                email="nalle@hailer.com"
                linkedInUrl="www.linkedin.com/in/juhaniyk">
            </app-teamperson-card>
            <app-teamperson-card
                name="Pia Enkovaara"
                imageSrc="/assets/img/team/pia_no_bg.webp"
                jobTitle="Project Manager"
                description="Keeps teams connected with Hailer's communication tools"
                email="pia.enkovaara@hailer.com"
                linkedInUrl="linkedin.com/in/enkovaarapia">
            </app-teamperson-card>
        </div>

        <h1>Customer support</h1>
        <p class="public-intro-text">
            Need help with technical issues such as process configurations, API, SDK? Or anything else? Our support team is happy to assist you!
        </p>

        <p>For assistance, contact <a href="https://form.hailer.com/eb945c01280f16d32b26b619161b8497" target="_blank" class="login-link">Customer Support</a> or explore our <a href="https://help.hailer.com" target="_blank" class="login-link">Help Center</a>, where you will find articles, FAQs and guides to help you make the most of Hailer.</p>

        <h1>Join Hailer team</h1>
        <p class="public-intro-text">
            Do you think that people cooperate poorly and do you want to improve this? Are you interested in the latest technologies and how to utilise them? Want to learn how different businesses work?
        </p>

        <p>
            If you are interested in joining our team, please leave an
            <a href="https://form.hailer.com/e181fb71f5b54b289aae40693b3635fa" target="_blank" class="login-link">Open application</a>.
        </p>

        <p>
            You can also inquire about internship and thesis worker positions at
            <a href="mailto:recruitement@hailer.com" class="login-link">recruitment&#64;hailer.com</a>
        </p>
    </div>

    <app-public-footer></app-public-footer>
</div>
