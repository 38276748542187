<!-- @format -->

<div class="login-background">
    <app-public-header [showLogin]="false"></app-public-header>
    <div class="public-sub-pages">
        <h2 style="text-align: center">
            {{ 'public.page-not-found.not_found_text' | transloco }}
        </h2>
    </div>
</div>
