<!-- @format -->

<div class="salesperson-card">
    <img class="salesperson-image" [src]="imageSrc" alt="{{ name }}" width="180px" />
    <div class="salesperson-info">
        <div>
            <h2 class="salesperson-name">{{ name }}</h2>
            <h3 class="salesperson-title">{{ jobTitle }}</h3>
            <p class="salesperson-description">{{ description }}</p>
        </div>
        <div class="salesperson-contact">
            <a class="contact-button" [href]="'tel:' + phoneNumber"> <mat-icon svgIcon="hailer-phone"></mat-icon></a>
            <a class="contact-button" [href]="'mailto:' + email"><mat-icon svgIcon="hailer-mail"></mat-icon></a>
            <a class="contact-button" [href]="linkedInUrl" target="_blank"><mat-icon svgIcon="hailer-linkedin"></mat-icon></a>
        </div>
    </div>
</div>
