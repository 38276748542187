/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { passwordComplexityValidator, passwordConfig } from 'app/_helpers/password-helper';
import { RPCService } from '../../../_services/rpc.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password-page.component.html',
    styleUrls: ['./forgot-password-page.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class ForgotPasswordPageComponent implements OnInit, OnDestroy {
    emailForm: UntypedFormGroup;
    status: any;
    ticketId: string;

    // For the password strength meter
    passwordInputFocused = false;
    passwordConfirmDirty = false;
    visiblePasswords = {
        new: false,
        newConfirm: false,
    };
    passwordScore: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    triedEmail: string;
    passwordForm: UntypedFormGroup;

    private password: UntypedFormControl;
    private passwordConfirm: UntypedFormControl;
    private email: UntypedFormControl;
    private sub: any;
    private resetKey: string;
    private onDestroy = new Subject<void>();

    constructor(private rpc: RPCService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.status = {
            hasVerifyingTicket: false,
            saving: false,
            emailSent: false,
            errorInEmail: null,
        };
        this.resetKey = null;
        this.createFormControls();
        this.createForms();
        this.sub = this.route.queryParams.subscribe(params => {
            this.ticketId = params.ticket;
            this.verifyResetTicket();
        });

        // First password input checks for matching after initial changes in passwordConfirm
        this.password.statusChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                if (!this.passwordConfirmDirty) {
                    return;
                }
                if (this.password.value !== this.passwordConfirm.value) {
                    this.passwordConfirm.setErrors({ passwordsDoNotMatch: true });
                } else {
                    this.passwordConfirm.setErrors(null);
                }
            },
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    async verifyResetTicket() {
        if (!this.ticketId) {
            return;
        }

        this.status.hasVerifyingTicket = true;
        try {
            const data = (await this.rpc.requestAsync('core.verify_reset_ticket', [this.ticketId])) as any;
            this.resetKey = data.reset_key;
            this.triedEmail = data.email;
        } catch (error) {
            this.ticketId = null;
            this.status.hasVerifyingTicket = false;
            console.error('Failed to verify reset ticketId', error);
        }

        this.status.hasVerifyingTicket = false;
    }

    async preparePasswordRecovery() {
        this.status.saving = true;
        this.email.patchValue(this.email.value.toLowerCase());
        this.triedEmail = this.email.value;

        try {
            await this.rpc.requestAsync('core.prepare_password_recovery', [this.email.value]);
            this.emailForm.reset();
            this.email.setValue(this.triedEmail);
            this.status.emailSent = true;
        } catch (error) {
            this.emailForm.reset();
            this.email.setValue(this.triedEmail);
            this.status.errorInEmail = error;
            console.error('Unable to prepare Password recovery', error);
        }

        this.status.saving = false;
    }

    async resetPassword() {
        this.status.saving = true;
        this.passwordForm.disable();

        try {
            await this.rpc.requestAsync('core.reset_password', [this.ticketId, this.resetKey, this.password.value]);
            this.router.navigate(['/login'], { queryParams: { withUsername: this.triedEmail } });
        } catch (error) {
            console.error('Unable to save new password ', error);
        }

        this.status.saving = false;
    }

    setPasswordInputFocus(state: boolean, event?: any) {
        // Don't change the focus if password visibility button is clicked
        if (!(!state && event?.relatedTarget?.id === 'newPasswordInputVisibilityIcon')) {
            this.passwordInputFocused = state;
        }
    }

    private createFormControls() {
        this.email = new UntypedFormControl(
            { value: '', disabled: this.status.saving },
            {
                validators: [Validators.required, Validators.email],
            }
        );

        this.password = new UntypedFormControl('', {
            validators: [
                Validators.required,
                Validators.minLength(passwordConfig.minLength),
                passwordComplexityValidator(this.passwordScore),
            ],
        });
        this.passwordConfirm = new UntypedFormControl('', {
            validators: [Validators.required, this.checkPasswordMatch.bind(this)],
        });
    }

    private createForms() {
        this.emailForm = new UntypedFormGroup({
            email: this.email,
        });
        this.passwordForm = new UntypedFormGroup({
            password: this.password,
            passwordConfirm: this.passwordConfirm,
        });
    }

    private checkPasswordMatch(control: AbstractControl): { [key: string]: any } | null {
        if (control.value === this.password.value) {
            return null;
        }
        return { passwordsDoNotMatch: true };
    }
}
