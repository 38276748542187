/** @format */

import { Theme } from './types';

export const lightTheme: Theme = {
    name: 'light',
    properties: {
        '--background-primary': '#FFF',
        '--background-primary-alt': '#F3F4F7',
        '--background-secondary': '#eaebf1',
        '--background-secondary-alt': '#E2E4EA',
        '--background-tertiary': '#DFE1E8',

        '--container-background': '#253c56',
        '--workflow-base-theme': '#405560',
        '--icon-color': '#fff',
        '--icon-badge-content': '#fff',
        '--kanban-background': '#617386',
        '--loading-overlay': 'rgba(255, 255, 255, 0.5)',
        '--mat-card': '#fff',
        '--discussion-background': '#F3F4F7',
        '--messenger-bg': 'invert(0)',
        '--messenger-bg-opacity': '0.03',

        '--text-high': 'rgba(0, 0, 0, 0.87)',
        '--text-medium': 'rgba(0, 0, 0, 0.60)',
        '--text-low': 'rgba(0, 0, 0, 0.38)',

        '--border-color': 'rgba(0, 0, 0, 0.12)',

        '--accent-400': '#36BA5F',
        '--transparent-accent-500': '#20a25015',
        '--accent-500': '#20a250',
        '--accent-800': '#138637',
        '--accent-text': '#0b7527',
        '--primary-500': '#253c56',
        '--primary-A400': '#2F6CEE',
        '--primary-A700': '#005ec2',
        '--primary-text': '#253c56',

        '--warn-text': '#e42b00',
        '--warn-icon': '#e42b00',

        '--user-message': '#fff',
        '--own-message': '#365D8C',
        '--own-message-text': '#F3F4F7',

        '--form-clear-button': 'rgba(0, 0, 0, 0.04)',
        '--hover-background': 'rgba(37, 60, 86, 0.04)',
        '--hover-background-light': 'rgba(37, 60, 86, 0.02)',
        '--date-divider': '#e0e0e0',
        '--activity-name-weight': 'unset',
        '--tooltip-background': '#000e20',

        '--filter-active-text': '#20344A',
        '--filter-inactive-text': '#999CA4',
        '--filter-active-bg': '#F3F4F7',
        '--filter-inactive-bg': '#F3F4F7',
        '--filter-hover': '#F3F4F7',
        '--bubble-text': '#ffffff',
        '--bubble-bg': '#17873F',
        '--remove-icon': '#323232',
        '--radio-button-inset': '#fff',
        '--active-icon': '#212121',
        '--inactive-icon': '#666666',

        '--icon-invert': '#212121',

        '--grey-400': '#C7CAD1',
        '--grey-100': '#fbfbfb',
        '--activity-filter-hover': '#F9FAFD',
        '--blue-100': '#F9FAFD',
        '--blue-700': '#20344A',

        '--side-nav-bg': '#E8E9EF',
        '--size-nav-cards': '#F9FAFD',

        '--sidenav-back-button-bg': '#1A2A3C',

        '--background': '#253c56',
        '--note-background': '#FFFADF',
        '--login-background-behind-gradient': '#0E0F12',
        '--low-gray': '#9BAEC6',

        '--subtitles': '#B8BBC2',
        '--dividers': '#DEE0E4',

        '--large-emoji-background': '#b9c3e129',
        '--warn-500': '#e42b00',
        '--lighten': '#ffffff26',
        '--darken': '#00000024',
        '--darken-strong': '#00000040',

        '--password-meter-background': '#ffffff',
        '--shadow-first': '#0000001f',
        '--shadow-second': '#344A620D',

        // Activity sidenav use only:
        '--sidenav-header-gradient-top': 'rgba(255,255,255,0.3)',
        '--sidenav-header-img-shadow': 'rgba(0, 0, 0, 0.12)',

        /* Correct / updated colors & names */
        '--white': '#ffffff',
        '--black': '#000000',
        '--starred-primary': '#FFC224',
        '--reaction-container': '#4C71A0',
        '--user-reaction-container': '#F3F4F7',
        '--user-mention-default': '#005ABA',
        '--user-mention-own-message': '#A8DBFF',
        '--links-default': '#005ABA',
        '--links-light': '#A8DBFF',
        '--hover': '#E9EBEE',

        '--accent-100': '#212121',
        '--add-button': '#20a250',
        '--user-link-default': '#005ABA',
        '--message-input-border-default': '#DEE0E4',
        '--input-border-active': '#17873F',
        '--message-input-bg': '#ffffff',
        '--more-messages-note': '#E9EBEE',
        '--small-text-gray': '#737478',
        '--small-text-darker-gray': '#585B65',
        '--timestamp-own-message': '#C2D5ED',
        '--message-deleted-own-message': '#DEE0E4',
        '--selected-discussion': '#D9E8F7',
        '--focused-message-bg': '#b6d0ea',
        '--highlight-message-bg': '#b6d0ea80',
        '--edit-textarea-bg': '#244875',
        '--function-btn-admin-bg': '#253C56',
        '--function-btn-admin-icon': '#F9FAFD',
        '--function-btn-user-bg': '#EBEEF6',
        '--function-btn-user-icon': '#0C1F30',

        '--checkbox-bg': '#20A250',
        '--menu-text': '#323232',

        '--input-default-bg': '#ffffff',
        '--input-highlight-bg': '#F3F4F7',
        '--input-highlight-bg-light-theme-only': '#F3F4F7',
        '--input-error-background': '#FEF0F0',
        '--input-placeholder': '#999CA4',
        '--input-placeholder-highlight-bg': '#6d7077',

        '--dialog-card-bg': '#ffffff',
        '--dialog-bg-highlight': '#F3F4F7',
        '--tag-bg': '#EBEEF6',
        '--activity-mention-tag': '#0b7527',
        '--activity-mention-tag-light': '#96ECB3',
        '--feed-card-bg': '#ffffff',
        '--feed-card-section-bg': '#F3F4F7',
      
        /* Public pages start */
        '--text-public-pages': '#EDEFF3',
        '--label-public-pages': '#898E99',
        '--input-default-bg-public-pages': '#1B1C1F',
        '--input-error-background-public-pages': '#1B1C1F',
        '--placeholder-public-pages': '#737478',
        '--input-border-default-public-pages': '#53565F',
        '--tertiary-btn-text-public-pages': '#EDEFF3',
        '--primary-btn-bg-public-pages': '#2cc45f',
        '--input-border-active-public-pages': '#96ECB3',
        '--warn-public-pages': '#F8A2A2',
        '--warn-text-public-pages': '#F8A2A2',
        /* Public pages end */

        /* Icons start */
        '--icon-default': '#212121',
        '--icon-destructive': '#E42B00',
        '--icon-success': '#17873F',
        '--icon-accent': '#253C56',
        /* Icons end */

        /* Buttons start */
        '--button-primary-background-default': '#17873F',
        '--button-primary-soft-background-default': '#7DD197',
        '--button-secondary-background-default': '#253C56',
        '--button-tertiary-default-background': '#F3F4F7',
        '--button-tertiary-highlight-background': '#ffffff',
        '--button-tertiary-soft-background': '#C2D5ED',
        '--button-destructive-default-background': '#E42B00',
        '--button-destructive-toned-down-background': '#ffffff',

        '--button-text-primary-soft': '#01592A',
        '--button-text-all-white': '#ffffff',
        '--button-text-default': '#253C56',
        '--button-text-tertiary': '#253C56',
        '--button-text-tertiary-soft': '#253C56',
        '--button-text-destructive-toned-down': '#E42B00',

        '--focus-outline': '#005ABA',
        '--focus-outline-disabled-opacity': '#005ABA4D',
        '--focus-outline-light': '#A8DBFF',

        '--button-primary-background-hover': '#006C32',
        '--button-primary-soft-background-hover': '#54C578',
        '--button-secondary-background-hover': '#172A3F',
        '--button-tertiary-default-hover': '#E9EBEE',
        '--button-tertiary-highlight-hover': '#E9EBEE',
        '--button-tertiary-soft-hover': '#9DB0C8',
        '--button-destructive-default-hover': '#CC1318',
        '--button-destructive-toned-down-hover': '#E9EBEE',

        '--button-text-primary-soft-hover-active': '#003217',
        '--button-text-tertiary-soft-hover-active': '#081826',

        '--button-primary-background-active': '#003217',
        '--button-primary-soft-background-active': '#3AA461',
        '--button-secondary-background-active': '#081826',
        '--button-tertiary-default-background-active': '#DEE0E4',
        '--button-tertiary-highlight-background-active': '#DEE0E4',
        '--button-tertiary-soft-active': '#7C94B1',
        '--button-destructive-default-background-active': '#9A1D00',
        '--button-destructive-toned-down-background-active': '#DEE0E4',

        '--button-text-tertiary-highlight-background-disabled': '#253C564D',
        '--button-text-destructive-toned-down-background-disabled': '#E42B004D',

        '--button-icon-color-default': '#212121',
        '--button-icon-color-destructive': '#E42B00',
        '--button-icon-color-success': '#17873F',
        '--button-icon-color-accent': '#253C56',
        '--button-icon-color-all-white': '#FFFFFF',
        '--button-icon-default-background-hover': '#E9EBEE',
        '--button-icon-default-background-active': '#DEE0E4',
        '--button-icon-all-white-background-hover': '#172A3F',
        '--button-icon-all-white-background-active': '#081826',
        /* Buttons end */
    },
    linkColors: [
        '#FFC2F2',
        '#FFC7D4',
        '#E2CCFF',
        '#C2D5FF',
        '#A8D8FF',
        '#8CF8FA',
        '#9BF9AF',
        '#FFED89',
        '#FFCB84',
        '#FFC6BD',
        '#FCFF68',
        '#BCDCAC',
        '#FFE5C7',
        '#C3FEE2',
        '#94FFE4',
    ],
    calendarLinkColors: [
        '#ff6e6e',
        '#7dabf2',
        '#ff5ce9',
        '#85d769',

        '#f73f3f',
        '#4cd5ff',
        '#c66cfd',
        '#ce9d69',

        '#de1165',
        '#016e6f',
        '#635bb5',
        '#7f6d24',

        '#e372c0',
        '#317d51',
        '#9076f2',
        '#d5a595',
    ],
};
