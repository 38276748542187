/** @format */

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { RPCService } from 'app/_services/rpc.service';
import { PersonalSettings } from '@app/models';
import { CoreService, SessionState } from 'app/_services/core.service';
import { JoinNetworkDialogComponent } from '../join-network-dialog/join-network-dialog.component';
import { UserService } from 'app/_services/user.service';
import { RoutingService } from 'app/_services/routing.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { TranslateService } from 'app/_services/translate.service';

@Component({
    selector: 'app-invite-review-page',
    templateUrl: './invite-review-page.component.html',
    styleUrls: ['./invite-review-page.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'core',
                alias: 'core',
            },
        },
    ],
})
export class InviteReviewPageComponent implements OnInit, OnDestroy {
    state: BehaviorSubject<SessionState>;
    user: BehaviorSubject<PersonalSettings>;

    invitation: any;
    page: string;
    invitationCodeError = false;
    rerouteTimer: number;
    isManagedUser: boolean;

    private loginState: any;
    private onDestroy = new Subject<void>();

    constructor(
        private core: CoreService,
        private rpc: RPCService,
        private router: RoutingService,
        private matDialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
        private translateService: TranslateService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.translateService.translationLoaded('core');
        await this.translateService.translationLoaded('misc');

        this.state = this.core.state;
        this.user = this.core.user;
        // Double negation casts to either true or false, used in .html
        this.isManagedUser = !!this.core.user.value?.managedUser;
        this.router.queryParams.pipe(takeUntil(this.onDestroy)).subscribe({
            next: params => {
                if (params.invite && !this.invitation) {
                    this.rpc
                        .request('company.check_invite', [params.invite])
                        .pipe(takeUntil(this.onDestroy))
                        .subscribe({
                            next: invite => {
                                this.invitation = invite;
                                this.invitationCodeError = false;
                                this.loginState = this.state.pipe(takeUntil(this.onDestroy)).subscribe({
                                    next: state => {
                                        if (state === 'authenticated') {
                                            this.openJoinNetwork(this.invitation);
                                        }
                                        this.cdr.detectChanges();
                                    },
                                });
                            },
                            error: (error: any) => {
                                console.error('failed to check invite', error);
                                this.invitationCodeError = true;
                                this.reroutingTimer();
                            },
                        });
                    this.page = 'review';
                }
                this.cdr.detectChanges();
            },
        });
    }

    ngOnDestroy(): void {
        if (this.loginState) {
            this.loginState.complete();
            this.loginState.next();
        }

        this.onDestroy.complete();
        this.onDestroy.next();
    }

    openJoinNetwork(invitation: any): void {
        const dialogData = new MatDialogConfig();
        dialogData.data = invitation;
        dialogData.panelClass = 'join-network-dialog';


        const dialogRef = this.matDialog.open(JoinNetworkDialogComponent, dialogData);
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: () => this.router.toDefaultView(),
            });
    }

    async acceptInvitation(key: string) {
        if (this.state.value === 'login') {
            return this.router.navigate(['/login'], { queryParams: { withUsername: this.invitation.email, invitationKey: key } });
        }

        this.userService
            .acceptInvitation(key)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    if (this.state.value === 'authenticated') {
                        this.router.toDefaultView();
                    }
                },
            });
    }

    async declineInvitation(key: string) {
        this.userService
            .declineInvitation(key)
            .pipe(take(1))
            .subscribe({
                next: () => this.router.toDefaultView(),
            });
    }

    async registerAndAcceptInvitation(registration, inviteKey: string) {
        this.loginState.complete();
        this.loginState.next();
        const { email, firstname, lastname, password } = registration;

        await this.rpc.requestAsync('v2.user.invite.register', [{ firstname, lastname, password }, inviteKey]);
        this.core
            .login(email, password)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: () => {
                    this.router.toDefaultView();
                },
                error: (error: any) => {
                    console.error('error logging in after registration via invite', error);
                },
            });
    }

    private reroutingTimer() {
        let time = 5;
        this.rerouteTimer = time;

        const interval = setInterval(() => {
            time -= 1;
            this.rerouteTimer = time;
            this.cdr.detectChanges();
            if (time === 0) {
                clearInterval(interval);
                this.router.toDefaultView();
            }
        }, 1000);
        this.cdr.detectChanges();
    }
}
