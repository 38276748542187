/** @format */

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { Capacitor } from '@capacitor/core';

import { RegistrationData } from '../../../_models/registrationData.model';
import { CoreService } from 'app/_services/core.service';
import { GoogleAuth2Service } from 'app/_services/google-auth2.service';
import { RoutingService } from 'app/_services/routing.service';
import { RegisterFormComponent } from '../../components/register-form/register-form.component';
import { TranslateService } from 'app/_services/translate.service';
import { RPCService } from 'app/_services/rpc.service';

@Component({
    selector: 'app-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class RegisterPageComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(RegisterFormComponent) registerForm;

    emailSentToAddress: string;
    page: string;
    googleLoginError: string;
    googleAuth2IsLoaded: boolean;
    emailRegistered = false;
    errorMsg = 'Email already registered!';
    errorMsgRegister: string;
    errorMsgRegisterForm: string;
    showRegistration = true;
    saving = false;
    errorCode: number;
    copyFailed = 'Public copy of network is not allowed';
    isMobile = Capacitor.isNativePlatform();
    copyNetworkId: string;

    private onDestroy = new Subject<void>();

    constructor(
        private auth: CoreService,
        private route: RoutingService,
        private googleAuth: GoogleAuth2Service,
        private cdr: ChangeDetectorRef,
        private translocoService: TranslocoService,
        private translateService: TranslateService,
        private rpc: RPCService
    ) {}

    async ngOnInit() {
        this.page = 'registerform';

        await this.translateService.translationLoaded('misc');

        this.auth.state.pipe(takeUntil(this.onDestroy)).subscribe({
            next: state => {
                this.route.queryParams.pipe(takeUntil(this.onDestroy)).subscribe({
                    next: params => {
                        if (params.cid) {
                            this.copyNetworkId = params.cid;
                        }

                        if (state === 'authenticated' && (!params.cid || this.saving)) {
                            this.routeToActivities();
                        }

                        if (state === 'authenticated' && params.cid) {
                            this.showRegistration = false;
                            this.cdr.detectChanges();
                        }
                    },
                });
            },
        });

        this.googleAuth.registerError.pipe(takeUntil(this.onDestroy)).subscribe(error => {
            this.googleLoginError = this.translocoService.translate('public.register-page.register_error');

            if (error.msg === this.copyFailed) {
                this.errorCode = 501;
                this.googleLoginError = `
                    ${this.translocoService.translate('public.register-page.configuration_not_available')} 
                    ${this.translocoService.translate('public.register-page.question_text')} 
                    ${
                        this.emailRegistered
                            ? this.translocoService.translate('public.login-form.button_login_2')
                            : this.translocoService.translate('public.register-form.button_register_2')
                    }?
                `;
                this.copyNetworkId = '';
            }

            this.saving = false;
            this.cdr.detectChanges();
        });
    }

    ngAfterViewInit() {
        if (this.showRegistration) {
            this.loadGoogleLibrary();
        }
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    register = async (registration: RegistrationData) => {
        this.emailSentToAddress = registration.email;
        this.errorMsgRegisterForm = '';
        this.saving = true;

        const subscription = await this.auth.callChallengeEndpoint('v3.user.register', [
            registration,
            { copyNetworkId: this.copyNetworkId },
        ]);
        subscription.subscribe({
            next: async () => {
                this.auth.login(registration.email, registration.password).subscribe({
                    next: () => {
                        this.page = 'emailsent';
                    },
                    error: err => {
                        this.saving = false;
                        console.error('Error happened while logging in', err);
                    },
                });
            },
            error: err => {
                this.errorMsgRegisterForm = err.msg;
                this.errorCode = err.code;
                if (err.code === 501) {
                    this.errorMsgRegisterForm = `
                    ${this.translocoService.translate('public.register-page.configuration_not_available')} 
                    ${this.translocoService.translate('public.register-page.question_text')} 
                    ${this.translocoService.translate('public.register-form.button_register_2')}?`;
                    this.copyNetworkId = '';
                } else {
                    this.errorMsgRegisterForm = this.translocoService.translate('public.register-page.register_error');
                }
                this.registerForm.saving = false;
                this.registerForm.errorMsgRegister = this.errorMsgRegisterForm;
                this.saving = false;
                this.cdr.detectChanges();
                console.error('Error happened while registering user', err);
            },
        });
    };

    getPage() {
        return this.page;
    }

    signInGoogleCapacitor(): void {
        this.googleAuth.signInCapacitor('register');
    }

    createWorkspaceCopy(): void {
        this.saving = true;

        this.rpc
            .request('v2.network.publicCopy', [{ copyNetworkId: this.copyNetworkId }])
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: networkId => {
                    this.switchToNetwork(networkId);
                },
                error: (err: any) => {
                    this.errorCode = err.code;
                    if (err.code === 501) {
                        this.errorMsgRegister = this.translocoService.translate('public.register-page.configuration_not_available');
                    } else {
                        this.errorMsgRegister = this.translocoService.translate('public.register-page.copy_workspace_error');
                    }

                    this.saving = false;
                    this.cdr.detectChanges();

                    console.error(this.translocoService.translate('public.register-page.copy_workspace_error'), err);
                },
            });
    }

    routeToDefaultView() {
        this.route.toDefaultView();
    }

    private routeToActivities() {
        this.route.navigate(['/activities']);
    }

    private switchToNetwork(networkId: string) {
        this.auth.switchNetwork(networkId).subscribe({
            next: () => {
                this.routeToActivities();
            },
            error: (error: any) => console.error(this.translocoService.translate('public.register-page.switch_workspace_error'), error),
        });
    }

    private loadGoogleLibrary() {
        const register = true;

        // Google button is not rendered (in most cases) without the timeout.
        setTimeout(() => {
            this.googleAuth.loadGoogleLibrary(this.copyNetworkId, { register });
        }, 200);

        this.cdr.detectChanges();
    }
}
