<!-- @format -->

<div class="login-background">
    <app-public-header [showLanguageSelector]="false" [signUpAsPrimaryBtn]="true"></app-public-header>
    <div class="public-sub-pages p-styles">
        <h1>Hailer Privacy Policy</h1>

        <p class="public-intro-text">
            Your privacy is important to us, and so is being transparent about how we collect, use, and share information about you.
        </p>

        <p>Data controller: Hailer Oy, 2772571-4, Rihkamatori 2, Porvoo, Finland</p>

        <p>
            All inquiries regarding this Privacy Policy should be directed to
            <a href="mailto:privacy@hailer.com" target="_blank" class="login-link">privacy&#64;hailer.com</a>
        </p>

        <p>
            This Privacy Policy covers the information we collect about you when you use our products or services or otherwise interact with
            us (for example, by attending our events or by communicating with us) unless a different policy is displayed.
        </p>

        <p>
            <strong
                >If you do not agree with this policy, do not access or use our services or interact with any other aspect of our
                business.</strong
            >
        </p>

        <p>
            This policy is divided into two sections. 1. section describes how Hailer application collects and processes your data and 2.
            section describes how our website and social media channels collect and process your data.
        </p>

        <h2>1. Hailer application</h2>

        <p>
            The Hailer Application is accessed from 1) Web Client (app.hailer.com), 2) Hailer Android App, 3) Hailer iOS App, and 4) Hailer
            API users. Hereon the term Application refers to any of these.
        </p>

        <h3>What data do we collect</h3>

        <p>
            To use our service and identify the user, certain data has to be collected for the service to be functional. This data includes
            but is not limited to email address, full name, and any other data inserted into the application.
        </p>

        <p>
            We collect payment and billing information when you register for certain paid services, i.e., you might provide payment
            information, such as payment card details, which we collect via secure payment processing services.
        </p>

        <p>
            In addition, our services may collect certain information automatically, including, but not limited to, the type of mobile
            device you use, your mobile device's unique device ID, the IP address of your mobile device, your mobile operating system, the
            type of mobile Internet browsers you use, and information about the way you use the Application.
        </p>

        <h3>How do we use the data we collect</h3>

        <p>
            We are always looking for ways to make our product smarter, faster, secure, integrated, and useful. We use data and collective
            learnings (including feedback) about how people use the application to troubleshoot, identify trends and usage, activity
            patterns, and areas for integration and product improvement, as well as develop new products, features, and technologies that
            benefit our users.
        </p>

        <h3>Hailer subprocessors</h3>

        <p>
            Hailer uses Amazon Web Services (AWS) infrastructure for providing the Hailer cloud service. The server infrastructure is
            located in the EU and AWS is committed to handle all data traffic compliant with EU data protection legislation. For more
            information please see the AWS GDPR legislation:
            <a href="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf" target="_blank" class="login-link"
                >https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf</a
            >.
        </p>

        <p>Hailer subprocessors:</p>

        <table class="public-table">
            <thead>
                <tr>
                    <th>Service Name</th>
                    <th>Company Name</th>
                    <th>Homepage</th>
                    <th>Used For</th>
                    <th>Data Processed</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Amazon Web Services (AWS)</td>
                    <td>Amazon Web Services, Inc.</td>
                    <td><a href="https://aws.amazon.com/" target="_blank" class="login-link">https://aws.amazon.com/</a></td>
                    <td>Server infrastructure</td>
                    <td>All Hailer data</td>
                </tr>
                <tr>
                    <td>MongoDB Atlas</td>
                    <td>MongoDB Inc.</td>
                    <td><a href="https://www.mongodb.com/" target="_blank" class="login-link">https://www.mongodb.com/</a></td>
                    <td>Databases</td>
                    <td>All Hailer data</td>
                </tr>
                <tr>
                    <td>Mailgun</td>
                    <td>Mailgun Technologies, Inc.</td>
                    <td><a href="https://mailgun.com/" target="_blank" class="login-link">https://mailgun.com/</a></td>
                    <td>Sending email from Hailer</td>
                    <td>Email address, name, password reset and invitation links</td>
                </tr>
                <tr>
                    <td>Splunk</td>
                    <td>Splunk Inc.</td>
                    <td><a href="https://www.splunk.com/" target="_blank" class="login-link">https://www.splunk.com/</a></td>
                    <td>Server log management</td>
                    <td>Infrastructure logs</td>
                </tr>
                <tr>
                    <td>Apple Push Notification Service (APN)</td>
                    <td>Apple</td>
                    <td><a href="https://apple.com/" target="_blank" class="login-link">https://apple.com/</a></td>
                    <td>Sending push notifications</td>
                    <td>Push notification content and metadata sent to Apple devices</td>
                </tr>
                <tr>
                    <td>Firebase Cloud Messaging (FCM)</td>
                    <td>Alphabet Inc.</td>
                    <td><a href="https://firebase.google.com/" target="_blank" class="login-link">https://firebase.google.com/</a></td>
                    <td>Sending web push notifications</td>
                    <td>Push notification metadata and content sent to web clients</td>
                </tr>
                <tr>
                    <td>Stripe</td>
                    <td>Stripe</td>
                    <td><a href="https://stripe.com/" target="_blank" class="login-link">https://stripe.com/</a></td>
                    <td>Online payments</td>
                    <td>Client payment information</td>
                </tr>
                <tr>
                    <td>Creamailer</td>
                    <td>Creamailer Oy</td>
                    <td><a href="https://www.creamailer.fi/" target="_blank" class="login-link">https://www.creamailer.fi/</a></td>
                    <td>Newsletters</td>
                    <td>Email address, name</td>
                </tr>
            </tbody>
        </table>

        <h3>Other privacy information</h3>

        <p>
            Hailer application End users: Many of our products are intended for use by organizations. In cases where the services are made
            available to you through an organization (e.g. your employer), that organization is the administrator of the workspace and is
            responsible for the workspace over which it has control. In these cases, please direct your data privacy questions to your
            administrator, as your use of the services is subject to that organization's policies. We are not responsible for the privacy or
            security practices of an administrator's organization, which may differ from this policy.
        </p>

        <h2>2. Hailer website and social media channels</h2>

        <h3>What data do we collect</h3>

        <p>
            We collect information about you when you input it into our website, social media accounts, or otherwise provide it directly to
            us through direct communications or interactions. Website visitors are the primary source of the personal, company, and
            technical information we collect, including the registration, contact information, etc. that are provided to us through Hailer’s
            digital assets or otherwise. We collect personal, company, and technical information from other sources, including but not
            limited to:
        </p>

        <ul>
            <li>3rd party platforms, these include advertising platforms, content on 3rd party sites or platforms, and social networks.</li>
        </ul>
        <p>We may disclose user-provided and automatically collected information in specific cases, namely:</p>

        <ul>
            <li>As required by law, such as to comply with a subpoena, or similar legal process;</li>
            <li>
                When we believe in good faith that disclosure is necessary to protect our rights, protect your safety, or the safety of
                others, investigate fraud, or respond to a government request;
            </li>
            <li>
                With our trusted services providers who work on our behalf. However, these service providers do not have independent use of
                the information we disclose to them and have agreed to adhere to the rules set forth in this privacy statement.
            </li>
            <li>
                If Hailer is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email
                and/or a prominent notice on our website of any change in ownership or uses of this information, as well as any choices you
                may have regarding this information.
            </li>
        </ul>

        <h3>How do we use the data we collect</h3>

        <p>
            The processing of personal data is primarily based on the legitimate interest of Hailer to ensure the functionality and security
            of our website and to provide information and services required by data subjects visiting the digital assets listed above.
            Personal data is also processed to develop and customize the website content for parties who are interested in our products and
            services.
        </p>

        <p>
            Through this website, we collect contact details from potential clients, including representatives of corporate clients, through
            completed forms to receive further information about our products and services. Certain identification data of the data subject
            can be stored through our website. These include the content of forms and email conversations with our Hailer representatives.
        </p>

        <h3>Information collected through web contact forms</h3>

        <ul>
            <li>Contact details. Your name, email address, telephone number, interest in our system, and the company you work for.</li>
        </ul>

        <h3>How to access and control your data</h3>

        <p>
            You have the right to request a copy of your information, to object to our use of your information (including for marketing
            purposes), to request the deletion or restriction of your data, or to request your information in a structured, electronic
            format. To create a request contact us at
            <a href="mailto:privacy@hailer.com" target="_blank" class="login-link">privacy&#64;hailer.com</a>
        </p>

        <p>
            In the following cases, we may decline exercising the right to process requests that are unreasonably repetitive, systematic,
            would require disproportionate technical effort, conflict with the privacy of others, would be extremely impractical, or for
            which access is not otherwise required.
        </p>

        <p>We may retain data subject’s information as necessary to comply with legal obligations, resolve disputes, or in backups.</p>

        <p>
            Manual Op out. For business-related purposes we have to keep some data until you opt-out manually from our digital assets or
            send a request to us for such deletion. These include your contact details you have provided when filling up a form on our
            digital assets. You can do this any time.
        </p>

        <h3>Changes to the policy</h3>

        <p>
            Hailer can make changes to this privacy policy in case of changes to the methods or purposes of the processing of personal data.
            We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here and informing you via email. You
            are advised to consult this Privacy Policy regularly for any changes.
        </p>
    </div>
    <app-public-footer></app-public-footer>
</div>
