/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@app/shared/shared.module';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { HailerLogoComponent } from './components/hailer-logo/hailer-logo.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { DevelopmentPageComponent } from './pages/development-page/development-page.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { LoginTokenComponent } from './pages/login-token/login-token.component';
import { EntraLoginPageComponent } from './pages/entra-login-page/entra-login-page.component';
import { DigiOneLoginPageComponent } from './pages/digione-login-page/digione-login-page.component';
import { TermsOfUsePageComponent } from './pages/terms-of-use-page/terms-of-use-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { PricingPageComponent } from './pages/pricing-page/pricing-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { PublicFooterComponent } from './components/public-footer/public-footer.component';
import { PublicHeaderComponent } from './components/public-header/public-header.component';
import { TeampersonCardComponent } from './components/teamperson-card/teamperson-card.component';

@NgModule({
    declarations: [
        ForgotPasswordPageComponent,
        HailerLogoComponent,
        LoginFormComponent,
        LoginPageComponent,
        PageNotFoundComponent,
        RegisterFormComponent,
        RegisterPageComponent,
        DevelopmentPageComponent,
        LoginTokenComponent,
        EntraLoginPageComponent,
        DigiOneLoginPageComponent,
        TermsOfUsePageComponent,
        PrivacyPolicyPageComponent,
        PricingPageComponent,
        ContactPageComponent,
        PublicFooterComponent,
        PublicHeaderComponent,
        TeampersonCardComponent,
    ],
    imports: [CommonModule, RouterModule, SharedModule, TranslocoRootModule],
    exports: [HailerLogoComponent, RegisterFormComponent, PublicHeaderComponent],
})
export class PublicModule {}
