/** @format */

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-pricing-page',
    templateUrl: './pricing-page.component.html',
    styleUrls: ['./pricing-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingPageComponent { }
