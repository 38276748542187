<!-- @format -->

<div class="login-background">
    <app-public-header [showLanguageSelector]="false" [showSignUp]="false"></app-public-header>
    <div class="pricing-content-wrapper">
        <div class="public-sub-pages">
            <h1>Pricing</h1>
            <p class="public-intro-text">
                Hailer is modular, flexible and grows with you. Start with the free version. No credit card is needed!
            </p>

            <div class="cards-wrapper">
                <div class="pricing-card smaller">
                    <div>
                        <p class="plan-name">Starter</p>
                        <p class="price"><span class="rate">0</span>€ /month</p>
                        <p class="pricing-desc">Perfect for managing small teams and exploring all features</p>
                    </div>
                    <div>
                        <p class="including">Including:</p>
                        <ul class="pricing-card-list">
                            <li>Full features incl. API & SDK</li>
                            <li>Unlimited users</li>
                            <li>Unlimited messages</li>
                            <li>Web, iOS and Android apps</li>
                            <li>100 activities</li>
                        </ul>
                        <a [routerLink]="'/register'" mat-flat-button class="primary-public-pages">Start now</a>
                    </div>
                </div>

                <div class="pricing-card">
                    <div>
                        <p class="plan-name">Growth</p>
                        <p class="price"><span class="rate">250</span>€ /month</p>
                        <p class="pricing-desc">Designed for small to medium-sized businesses that need more flexibility with increased activity limits</p>
                    </div>
                    <div>
                        <p class="including">Including:</p>
                        <ul class="pricing-card-list">
                            <li>Full features incl. API & SDK</li>
                            <li>Unlimited users</li>
                            <li>Unlimited messages</li>
                            <li>Web, iOS and Android apps</li>
                            <li>3000 activities</li>
                        </ul>
                        <a [href]="'https://form.hailer.com/846f393a84406e85dbc150ffdfff8352'"
                        target="_blank" mat-flat-button class="tertiary-highlight-public-pages">Contact us</a>
                    </div>
                </div>

                <div class="pricing-card smaller">
                    <div>
                        <p class="plan-name">Enterprise</p>
                        <p class="pricing-desc">Manage every aspect of your business without limitations and replace multiple systems with one powerful solution</p>
                    </div>
                    <div>
                        <p class="including">Including everything in Growth, plus:</p>
                        <ul class="pricing-card-list">
                            <li>Tailored training package</li>
                            <li>Dedicated customer success rep</li>
                            <li>Tech support (API & SDK)</li>
                            <li>Unlimited activities</li>
                        </ul>
                        <a
                            [href]="'https://form.hailer.com/846f393a84406e85dbc150ffdfff8352'"
                            target="_blank"
                            mat-flat-button
                            class="tertiary-highlight-public-pages"
                            >Contact us</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-public-footer></app-public-footer>
</div>
