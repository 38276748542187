/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { CoreService } from 'app/_services/core.service';
import { TranslateService } from 'app/_services/translate.service';

@Component({
    selector: 'app-public-header',
    templateUrl: './public-header.component.html',
    styleUrls: ['./public-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'public', alias: 'public' },
        },
    ],
})
export class PublicHeaderComponent implements OnInit {

    // Note! All three buttons (log in, sign up and language selector) don't fit on mobile size screens yet
    @Input() showLogin = true;
    @Input() showSignUp = true;
    @Input() showLanguageSelector = true;
    @Input() loginAsPrimaryBtn = false;
    @Input() signUpAsPrimaryBtn = false;

    languageMenuOpen = false;
    languages: string[] = ['en', 'fi', 'sv'];
    selectedLanguage: string;
    miscTranslationsLoaded = false;

    constructor(
        private core: CoreService,
        private transloco: TranslocoService,
        private translateService: TranslateService,
        private ngRouter: Router,
        private cdr: ChangeDetectorRef
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.core.getState() !== 'authenticated') {
            this.setLanguage();
        }

        await this.translateService.translationLoaded('core');
        await this.translateService.translationLoaded('misc');
        this.miscTranslationsLoaded = true;
        await this.translateService.translationLoaded('public');

        this.cdr.detectChanges();
    }

    async resetPage(): Promise<void> {
        // Parse the current URL to capture the path and query parameters
        const currentUrlTree = this.ngRouter.parseUrl(this.ngRouter.url);
        const currentUrl = currentUrlTree.root.children.primary?.segments.map(segment => segment.path).join('/') || '/';
        const queryParams = currentUrlTree.queryParams;
        // Handle the root case, where the page needs to redirect to /login, otherwise translations are not updated
        const targetUrl = this.ngRouter.url === '/' ? '/login' : currentUrl;

        // Perform the navigation to ensure translations are updated and then navigate back to the original page
        await this.ngRouter.navigateByUrl('/', { skipLocationChange: true });
        await this.ngRouter.navigate([targetUrl], { queryParams });
    }

    async changeLanguage(language: string): Promise<void> {
        this.selectedLanguage = language;
        this.transloco.setActiveLang(language);
        localStorage.setItem('selectedLanguage', language);
        await this.resetPage();
    }

    setLanguage(): void {
        const selectedLanguage = localStorage.getItem('selectedLanguage');

        if (!selectedLanguage || selectedLanguage === 'null') {
            this.selectedLanguage = this.languages[0] ?? 'en';
            localStorage.setItem('selectedLanguage', this.selectedLanguage);
        } else {
            this.selectedLanguage = selectedLanguage;
        }

        this.transloco.setActiveLang(this.selectedLanguage);
    }
}
