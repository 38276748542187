/** @format */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
    selector: 'app-teamperson-card',
    templateUrl: './teamperson-card.component.html',
    styleUrls: ['./teamperson-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class TeampersonCardComponent {
    @Input() imageSrc: string;
    @Input() name: string;
    @Input() jobTitle: string;
    @Input() description: string;
    @Input() phoneNumber?: string;
    @Input() email?: string;
    @Input() linkedInUrl?: string;
}
